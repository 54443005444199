import styled from 'styled-components'
import { mediaQuery } from '../../lib/service/service'
import { SCREEN_MOBILE } from '../../lib/const/breakpoint'

const RatesBlockSC = styled('section')`
    padding: 0 0px 100px 0;
`

const RatesWrapeprSC = styled('div')`
    display: flex;
    justify-content: space-between;
    ${mediaQuery(SCREEN_MOBILE)} {
        flex-direction: column;
    }
`

export const styleRatesBlock = () => ({
    RatesBlockSC,
    RatesWrapeprSC,
})
