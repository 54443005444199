import { SCREEN_MOBILE, SCREEN_TABLE } from '../../lib/const/breakpoint'
import { mediaQuery } from '../../lib/service/service'

const { default: styled } = require('styled-components')

export const ContainerSC = styled('div')`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 100px;
    overflow-x: hidden;
    ${mediaQuery(SCREEN_TABLE)} {
        padding: 0 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 0 10px;
    }
`
