import { styleFreedomBlock } from './StyleFreedomBlock'
import TitleUI from '../../UI/TitleUI/TitleUI'
import Container from '../Container/Container'
import FeatureItem from './FeatureItem/FeatureItem'
import { useResize } from './../../lib/hooks/useResize'
import { useState } from 'react'
import ModalUI from '../../UI/ModalUI/ModalUI'

const FreedomBlock = ({ sectionRef }) => {
    const [isShowModal, setIsShowModal] = useState(false)
    const [imgModal, setImgModal] = useState('')
    const [isModalSize, setIsModalSize] = useState({})
    const { isScreenTable } = useResize()
    const onClose = () => {
        setIsShowModal(false)
    }
    const onClickMobile = (img, width, height) => {
        return () => {
            if (!isScreenTable) {
                setIsShowModal(true)
                setImgModal(img)
            }
            setIsModalSize({ width: width, height: height })
        }
    }

    const textBlock = [
        {
            text: 'Линейные диаграммы',
            img: '/FreedomImg/LineСhart.webp',
            isDev: false,
            width: 600,
            height: 300,
        },
        {
            text: 'Круговые диаграммы',
            img: '/FreedomImg/PieСhart.webp',
            isDev: false,
            width: 300,
            height: 300,
        },
        {
            text: 'Гистограммы',
            img: '/FreedomImg/Gastrogram.webp',
            isDev: false,
            width: 564,
            height: 300,
        },
        {
            text: 'Графики корреляции',
            img: '/FreedomImg/CorrelationGraph.webp',
            isDev: false,
            width: 291,
            height: 232,
        },
        {
            text: 'Дисперсии и многое другое',
            img: '/FreedomImg/Variance.webp',
            isDev: false,
            width: 300,
            height: 300,
        },
    ]

    const graphicsBlock = [
        {
            text: 'Любые арифметические вычисления',
            img: '/FreedomImg/Arithmetic.png',
            isDev: false,
            width: 400,
            height: 89,
        },
        {
            text: 'Списки, сортировка, фильтрация',
            img: '/FreedomImg/Sort.png',
            isDev: false,
            width: 400,
            height: 270,
        },
        {
            text: 'Нестрогий поиск',
            img: '/FreedomImg/LaxSearch.png',
            isDev: false,
            width: 400,
            height: 159,
        },
        {
            text: 'Редактирование данных',
            img: '',
            isDev: false,
        },
        { text: 'Создание таблиц', img: '', isDev: false },
        { text: 'Обработка несортированных данных', img: '', isDev: false },
    ]

    return (
        <FreedomBlockWrapperSC ref={sectionRef} id="opportunities">
            {isShowModal && (
                <ModalUI onClose={onClose}>
                    <ImgModalSC isModalSize={isModalSize} src={imgModal} />
                </ModalUI>
            )}
            <TitleUI>полная свобода</TitleUI>
            <Container>
                <FeaturesBlockSC>
                    <FeatureItemTitleSC>Графические ответы</FeatureItemTitleSC>
                    <FeatureListSC>
                        {textBlock.map(
                            ({ text, img, isDev, width, height }) => {
                                return (
                                    <FeatureItem
                                        onClickMobile={onClickMobile}
                                        key={text}
                                        isDev={isDev}
                                        img={img}
                                        text={text}
                                        width={width}
                                        height={height}
                                    />
                                )
                            }
                        )}
                    </FeatureListSC>
                </FeaturesBlockSC>
                <FeaturesBlockSC>
                    <FeatureItemTitleSC>Текстовые ответы</FeatureItemTitleSC>
                    <FeatureListSC>
                        {graphicsBlock.map(
                            ({ text, img, isDev, width, height }) => {
                                return (
                                    <FeatureItem
                                        onClickMobile={onClickMobile}
                                        key={text}
                                        img={img}
                                        isDev={isDev}
                                        qqqqqqqqqqqisDev={isDev}
                                        text={text}
                                        isText={true}
                                        width={width}
                                        height={height}
                                    />
                                )
                            }
                        )}
                    </FeatureListSC>
                </FeaturesBlockSC>
                <DevFeatureWrapperSC>
                    <DevTitleWrapperSC>
                        <DevTitleSC>Табличные ответы</DevTitleSC>
                        <ElipseWrapperSC>
                            <ElipseSC />
                            <ElipseSC />
                            <ElipseSC />
                        </ElipseWrapperSC>
                        {/* <DevMessageSC>В разработке</DevMessageSC> */}
                    </DevTitleWrapperSC>
                </DevFeatureWrapperSC>
            </Container>
        </FreedomBlockWrapperSC>
    )
}

const {
    ElipseSC,
    DevTitleSC,
    ImgModalSC,
    DevMessageSC,
    FeatureListSC,
    ElipseWrapperSC,
    FeaturesBlockSC,
    DevTitleWrapperSC,
    FeatureItemTitleSC,
    DevFeatureWrapperSC,
    FreedomBlockWrapperSC,
} = styleFreedomBlock()

export default FreedomBlock
