import styled from 'styled-components'
import { mediaQuery } from '../../lib/service/service'
import {
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_TABLE,
} from '../../lib/const/breakpoint'

export const TitleSC = styled('h2')`
    font-weight: 600;
    font-size: 35px;
    line-height: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #fdfdfd;
    margin: 0 auto;
    margin-bottom: 87px;
    max-width: 1350px;
    width: 100%;
    padding: 0 50px;
    ${mediaQuery(SCREEN_TABLE)} {
        font-size: 30px;
        margin-bottom: 37px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        font-size: 25px;
        max-width: 1290px;
        padding: 0 20px;
    }

    ${mediaQuery(SCREEN_MOBILE)} {
        max-width: 1270px;
        font-size: 24px;
        padding: 0 10px;
        margin-bottom: 20px;
    }
    ${mediaQuery(410)} {
        font-size: 22px;
    }
    ${mediaQuery(381)} {
        font-size: 18px;
        margin-bottom: 25px;
    }
`
