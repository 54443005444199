import styled from 'styled-components'
import { mediaQuery } from '../../../../lib/service/service'

const SuccessWrapperSC = styled('div')`
    transition: all ease-in-out 0.2s;
    opacity: ${({ isAnimation }) => (isAnimation ? 0 : 1)} 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 361px;
    padding: 0px 10px;
    border: 2px solid #56a2fd;
    border-radius: 20px;
    ${mediaQuery(871)} {
        margin: 0 10px 10px 10px;
        border-color: #eaf0fa;
    }
`

const SuccessTitleSC = styled('h3')`
    font-weight: 800;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #56a2fd;
    margin-bottom: 10px;
    ${mediaQuery(871)} {
        color: #eaf0fa;
    }
`

const SuccessDescrSC = styled('p')`
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #56a2fd;
    ${mediaQuery(871)} {
        color: #eaf0fa;
    }
`

export const styleSuccesBlock = () => ({
    SuccessTitleSC,
    SuccessDescrSC,
    SuccessWrapperSC,
})
