import styled from 'styled-components'
import { mediaQuery } from '../../lib/service/service'
import {
    SCREEN_MD,
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_SMALL_MOBILE,
} from '../../lib/const/breakpoint'

const TaskBlockSC = styled('section')`
    padding-top: 117px;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        padding-top: 62px;
    }
    ${mediaQuery(380)} {
        padding-top: 50px;
    }
`

// const TitleBlockSC = styled('h2')`
//     font-weight: 600;
//     font-size: 35px;
//     line-height: 100%;
//     letter-spacing: 0.01em;
//     text-transform: uppercase;
//     color: #fdfdfd;
//     margin: 0 auto;
//     margin-bottom: 87px;
//     max-width: 1290px;
//     width: 100%;
//     padding: 0 20px;
// `

const SubTitleSC = styled('h3')`
    font-weight: 600;
    font-size: 25px;
    line-height: 140%;
    text-align: center;
    color: #fdfdfd;
    margin-bottom: 47px;
    ${mediaQuery(SCREEN_MD)} {
        font-size: 20px;
        margin-bottom: 51px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 19px;
        margin-bottom: 18px;
    }
    ${mediaQuery(SCREEN_SMALL_MOBILE)} {
        margin-bottom: 25px;
        font-size: 14px;
    }
`

const TaskCardWrapperSC = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${mediaQuery(SCREEN_MOBILE)} {
        flex-direction: column;
    }
`

export const StyleTaskBlock = () => ({
    SubTitleSC,
    TaskBlockSC,
    // TitleBlockSC,
    TaskCardWrapperSC,
})
