import styled from 'styled-components'
import { ReactComponent as Check } from './assets/Check.svg'
import { ContainerSC } from '../Container/StyleContainer'
import { mediaQuery } from '../../lib/service/service'
import { SCREEN_MOBILE } from '../../lib/const/breakpoint'

const CustomContainerSC = styled(ContainerSC)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 0px;
    }
`
const BetaBlockSC = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 823px;
    width: 100%;
    background-image: url('/BetaBlockAndFormBG.webp');
    background-size: cover;
    background-repeat: no-repeat;
    ${mediaQuery(SCREEN_MOBILE)} {
        min-height: auto;
        padding-top: 40px;
    }
`

const BetaTitleSC = styled('h2')`
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    color: #56a2fd;
    margin-bottom: 52px;
    text-align: center;
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 29px;
        margin-bottom: 25px;
        padding: 0 20px;
    }
    ${mediaQuery(380)} {
        font-size: 21px;
        margin-bottom: 30px;
    }
`

const BetaListSC = styled('ul')`
    margin-bottom: 52px;
    max-width: 500px;
    width: 100%;
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-bottom: 25px;
        padding: 0 10px;
    }
    ${mediaQuery(380)} {
        margin-bottom: 30px;
    }
`

const BetaListItemSC = styled('li')`
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    ${mediaQuery(SCREEN_MOBILE)} {
        max-width: 394px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 15px;
    }
`

const BetaListIconSC = styled(Check)`
    width: 20px;
    height: 20px;
    margin-right: 20px;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 16px;
        height: 16px;
    }
    ${mediaQuery(380)} {
        width: 14px;
        height: 14px;
    }
`

const BetaListItemTextSC = styled('p')`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 14px;
    }
    ${mediaQuery(380)} {
        font-size: 12px;
    }
`

const BetaButtonSC = styled('a')`
    transition: all ease-in-out 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 310px;
    height: 50px;
    background: #56a2fd;
    border-radius: 39px;
    font-weight: 700;
    font-size: 20px;
    line-height: 148%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #fdfdfd;
    &:hover {
        background: #81baff;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-weight: 600px;
        width: 100%;
        height: 48px;
        font-size: 18px;
        border-radius: 0px;
    }
`

export const styleBetaBlock = () => ({
    BetaListSC,
    BetaTitleSC,
    BetaBlockSC,
    BetaButtonSC,
    BetaListItemSC,
    BetaListIconSC,
    CustomContainerSC,
    BetaListItemTextSC,
})
