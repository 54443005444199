import { createPortal } from 'react-dom'
import { styleModalUI } from './StyleModalUI'
import { useModalUI } from './useModalUI'

const ModalUI = ({ children, onClose }) => {
    useModalUI()
    return createPortal(
        <OverlaySC onClick={onClose}>
            <CloseSC onClick={onClose} />
            <ChildrenWrapperSC onClick={(e) => e.stopPropagation()}>
                {children}
            </ChildrenWrapperSC>
        </OverlaySC>,
        document.body
    )
}

const { CloseSC, OverlaySC, ChildrenWrapperSC } = styleModalUI()

export default ModalUI
