import Container from '../Container/Container'
import { RegisterTodayStyle } from './RegisterTodayStyle'

const RegisterToday = () => {
    return (
        <WrapperSC>
            <TopWrapperSC>
                <TitleSC>Регистрируйтесь сегодня!</TitleSC>
                <TextSC>
                    Получите доступ к интеллектуальной системе, понимающей ваш
                    запрос и выдающей результат без использования формул
                </TextSC>
                <ButtonSC
                    href={`${process.env.REACT_APP_DOMAIN}/signup`}
                >
                    Регистрация
                </ButtonSC>
            </TopWrapperSC>
            <BottomWrapperSC />
        </WrapperSC>
    )
}

const { TextSC, TitleSC, ButtonSC, WrapperSC, TopWrapperSC, BottomWrapperSC } =
    RegisterTodayStyle()

export default RegisterToday
