import styled from 'styled-components'
import { mediaQuery } from '../../lib/service/service'
import { SCREEN_MOBILE } from '../../lib/const/breakpoint'

const WrapperSC = styled('div')``

const TopWrapperSC = styled('div')`
    height: 278px;
    background-image: url('/BetaBlockAndFormBG.webp');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    ${mediaQuery(SCREEN_MOBILE)} {
        padding-top: 20px;
        height: 205px;
    }
`

const BottomWrapperSC = styled('div')`
    background: #131218;
    height: 128px;
    ${mediaQuery(SCREEN_MOBILE)} {
        height: 89px;
    }
`

const TitleSC = styled('h2')`
    color: #56a2fd;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    color: #56a2fd;
    margin-bottom: 26px;
    ${mediaQuery(SCREEN_MOBILE)} {
        color: #56a2fd;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        margin-bottom: 18px;
    }
    ${mediaQuery(350)} {
        font-size: 21px;
        margin-bottom: 40px;
    }
`

const TextSC = styled('p')`
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 26px;
    max-width: 538px;
    width: 100%;
    ${mediaQuery(SCREEN_MOBILE)} {
        max-width: 323px;
        font-size: 14px;
        margin-bottom: 18px;
    }
    ${mediaQuery(350)} {
        font-size: 12px;
        max-width: 283px;
        margin-bottom: 40px;
    }
`

const ButtonSC = styled('a')`
    border-radius: 78px;
    background: #56a2fd;
    padding: 15px 60px;
    box-shadow: 0px 4px 12px 0px rgba(134, 189, 255, 0.69);
    color: #fdfdfd;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%; /* 29.6px */
    letter-spacing: 0.4px;
    &:hover {
        background: #81baff;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        fill-opacity: 18px;
    }
    ${mediaQuery(350)} {
        font-size: 16px;
    }
`

export const RegisterTodayStyle = () => ({
    TextSC,
    TitleSC,
    ButtonSC,
    WrapperSC,
    TopWrapperSC,
    BottomWrapperSC,
})
