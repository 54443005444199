import BetaBlock from './Component/BetaBlock/BetaBlock'
import FormBlock from './Component/FormBlock/FormBlock'
import FreedomBlock from './Component/FreedomBlock/FreedomBlock'
import IsSimpleBlock from './Component/IsSimpleBlock/IsSimpleBlock'
import RatesBlock from './Component/RatesBlock/RatesBlock'
import TaskBlock from './Component/TaskBlock/TaskBlock'
import TitleBlock from './Component/TitleBlock/TitleBlock'
import MainLayout from './Layout/MainLayout'
import GlobalStyle from './Style/GlobalStyle'
import ModalConfirmation from './Component/ModalConfirmation/ModalConfirmation'
import { useRef } from 'react'
import RegisterToday from './Component/RegisterToday/RegisterToday'

function App() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    })
    let token = params.token

    const whereToApplyRef = useRef(null)
    const howToUseRef = useRef(null)
    const opportunitiesRef = useRef(null)
    const howToBuyRef = useRef(null)
    const priceRef = useRef(null)

    return (
        <>
            <GlobalStyle />
            <MainLayout
                sectionRefs={{
                    priceRef: priceRef,
                    howToUseRef: howToUseRef,
                    howToBuyRef: howToBuyRef,
                    whereToApplyRef: whereToApplyRef,
                    opportunitiesRef: opportunitiesRef,
                }}
            >
                {!!token && <ModalConfirmation token={token} />}
                <TitleBlock />
                <TaskBlock sectionRef={whereToApplyRef} />
                <IsSimpleBlock sectionRef={howToUseRef} />
                <FreedomBlock sectionRef={opportunitiesRef} />
                {/* <BetaBlock sectionRef={howToBuyRef} /> */}
                <RatesBlock sectionRef={priceRef} />
                <RegisterToday />
                {/* <FormBlock /> */}
            </MainLayout>
        </>
    )
}

export default App
