import { useEffect, useState } from 'react'
import { styleGifCard } from './StyleGifCard'

const GifCard = ({
    isRightVersion = false,
    description,
    Title,
    videoSrc,
    blockRef,
}) => {
    const [isRender, setIsRender] = useState(false)

    useEffect(() => {
        setIsRender(true)
    }, [])

    return (
        <WrapperCardSC $isRightVersion={isRightVersion}>
            <TextWrapperSC $isRightVersion={isRightVersion}>
                <TitleSC>{Title}</TitleSC>
                <DescriptionSC>{description}</DescriptionSC>
            </TextWrapperSC>
            {isRender && (
                <VideoSC
                    src={videoSrc}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    width={'100%'}
                    height={'100%'}
                    loading="lazy"
                ></VideoSC>
            )}
        </WrapperCardSC>
    )
}

const { TitleSC, VideoSC, TextWrapperSC, DescriptionSC, WrapperCardSC } =
    styleGifCard()

export default GifCard
