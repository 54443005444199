import axios from 'axios'
import emailjs from '@emailjs/browser'

export const BASE_URL = `${process.env.REACT_APP_DOMAIN}/api`

const instance = axios.create({
    baseURL: BASE_URL,
})

// export const sendEmailFromCompany = (form) => {
//     console.log(form)
//     return emailjs.send(
//         'service_57d146m',
//         'template_te21308',
//         form,
//         'owDOPGx-9QHWt5TCJ'
//     )
// }

export const emailSend = (jsonData) => {
    return instance.post(`/form/${jsonData}`)
}
export const tokenSend = (token) => {
    return instance.post(token)
}
