import { StyleTaskBlock } from './StyleTaskBlock'
import Container from '../Container/Container'
import TaskCard from './TaskCard/TaskCard'
import TitleUI from '../../UI/TitleUI/TitleUI'

const TaskBlock = ({ sectionRef }) => {
    return (
        <TaskBlockSC id="whereToUse">
            <TitleUI>достигайте целЕЙ быстрее</TitleUI>
            <Container>
                <SubTitleSC ref={sectionRef}>
                    Аналитика, графики, сводные отчеты - ИИ сделает все за вас
                </SubTitleSC>
                <TaskCardWrapperSC>
                    <TaskCard
                        plusColor={'#92C4FF'}
                        iconVersion={'one'}
                        subTitle={'Выполняйте задачи быстрее'}
                        title={'Личные задачи'}
                        ListArr={[
                            'Не тратьте время на работу в Excel',
                            'Обрабатывайте любые данные - от школьных задач до финансовых отчетов',
                            'Создавайте инфографику одним запросом',
                        ]}
                    />
                    <TaskCard
                        plusColor={'#56A2FD'}
                        iconVersion={'two'}
                        subTitle={'Повысьте эффективность аналитики'}
                        title={'Для задач бизнеса'}
                        ListArr={[
                            'Ускорьте работу со своими базами данных',
                            'Быстрее получайте отчеты от подразделений',
                            'Уменьшите расходы на обучение новых сотрудников',
                        ]}
                    />
                </TaskCardWrapperSC>
            </Container>
        </TaskBlockSC>
    )
}

const { SubTitleSC, TaskBlockSC, TaskCardWrapperSC } = StyleTaskBlock()

export default TaskBlock
