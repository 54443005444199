import styled from 'styled-components'

import { ReactComponent as Arrow } from './assets/arrow-right.svg'
import { ReactComponent as Plux } from './assets/Plus.svg'
import { mediaQuery } from '../../../lib/service/service'
import {
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_TABLE,
} from '../../../lib/const/breakpoint'

const RatesCardWrapperSC = styled('div')`
    padding: 80px 40px 40px 40px;
    max-width: 485px;
    width: 49%;
    background: #3c394b;
    border: 2px solid ${({ $color }) => $color};
    backdrop-filter: blur(10px);
    border-radius: 40px;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        padding: 40px 20px 20px 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        overflow: hidden;
        padding: 25px 0 0 0;
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
        border-radius: 10px;
    }
`

const RatesTypeSC = styled('span')`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    height: 28px;
    border: 1px solid ${({ $color }) => $color};
    border-radius: 30px;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: ${({ $color }) => $color};
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-left: 10px;
        /* padding-left: 10px; */
    }
`

const PriceWrapperSC = styled('div')`
    display: flex;
    align-items: end;
    flex-wrap: wrap;
    margin-bottom: 20px;
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-left: 10px;
        margin-bottom: 10px;
    }
`

const PriceSC = styled('span')`
    font-weight: 200;
    font-size: 40px;
    line-height: 148%;
    letter-spacing: 0.01em;
    text-decoration-line: line-through;
    color: #fdfdfd;
    opacity: 0.2;
    margin-right: 20px;
    ${mediaQuery(SCREEN_TABLE)} {
        font-size: 30px;
    }
    ${mediaQuery(815)} {
        font-size: 40px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        font-size: 30px;
    }
    ${mediaQuery(710)} {
        font-size: 40px;
    }
    ${mediaQuery(640)} {
        font-size: 35px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 40px;
    }
    ${mediaQuery(400)} {
        font-size: 28px;
    }
`

const FreeSC = styled('span')`
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: ${({ $color }) => $color};
    ${mediaQuery(400)} {
        font-size: 12px;
    }
`

const ListSC = styled('ul')`
    min-height: 220px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - 20px);
        height: 1px;
        background: #fdfdfd;
        opacity: 0.2;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        height: 1px;
        opacity: 0.2;
        background: #fdfdfd;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding-left: 10px;
        padding-right: 10px;
        min-height: 112px;
        &::after {
            content: '';
        }
    }
`

const ListItemSC = styled('li')`
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
    ${mediaQuery(SCREEN_MOBILE)} {
        height: 40px;
    }
`

const ListItemIconSC = styled(Plux)`
    width: 20px;
    height: 20px;
    margin-right: 20px;
    & > path {
        fill: ${({ $color }) => $color};
    }
    ${mediaQuery(410)} {
        width: 16px;
        height: 16px;
    }
`

const ListItemTextSC = styled('p')`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #fdfdfd;
    ${mediaQuery(410)} {
        font-size: 12px;
    }
`

const TestButtonSC = styled('a')`
    transition: all ease-in-out 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 7px 12px;
    max-width: 405px;
    width: 100%;
    height: 38px;
    background: ${({ $color }) => $color};
    border-radius: 59px;
    &:hover {
        background: #81baff;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 100%;
        max-width: 100%;
        height: 41px;
        border-radius: 0;
    }
`
const ButtonIconSC = styled(Arrow)`
    display: flex;
    align-items: center;
    width: 21px;
    height: 21px;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 16px;
        height: 16px;
    }
`

const ButtonTextSC = styled('span')`
    font-weight: 600;
    font-size: 16px;
    line-height: 148%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #fdfdfd;
    margin-right: 12px;
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 18px;
    }
    ${mediaQuery(400)} {
        font-size: 14px;
    }
`

export const styleRatesCard = () => ({
    FreeSC,
    ListSC,
    PriceSC,
    ListItemSC,
    RatesTypeSC,
    ButtonIconSC,
    ButtonTextSC,
    TestButtonSC,
    ListItemTextSC,
    ListItemIconSC,
    PriceWrapperSC,
    RatesCardWrapperSC,
})
