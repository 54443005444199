import styled from 'styled-components'
import { ReactComponent as Logo } from './assets/Logo.svg'
import { mediaQuery } from '../../../lib/service/service'
import {
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_TABLE,
} from '../../../lib/const/breakpoint'

const FeatureWrapperSC = styled('li')`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    height: 55px;
    background: #3c394b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    ${({ $isDev }) =>
        $isDev
            ? 'border: 1px solid rgba(121, 128, 150, 0.2)'
            : 'border: 1px solid #3c394b'};
    ${({ $isDev }) => $isDev && 'opacity:0.4'};

    &:hover {
        & > img {
            opacity: 1;
            visibility: visible;
        }
        ${({ $isDev }) => ($isDev ? '' : 'border: 1px solid #56a2fd')};
    }

    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        margin-bottom: 15px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        height: 40px;
        padding: 10px;
        margin-bottom: 5px;
    }
`
const LeftWrapperSC = styled('div')`
    display: flex;
    align-items: center;
`

const LogoSC = styled(Logo)`
    width: 25px;
    height: 25px;
    margin-right: 20px;
    & > path {
        fill: ${({ $isDev }) => $isDev && '#798096'};
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 12px;
        height: 12px;
    }
`
const TextSC = styled('p')`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: ${({ $isDev }) => ($isDev ? '#8A8B97' : '#fdfdfd')};
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 14px;
        text-align: start;
        margin-right: 5px;
    }
    ${mediaQuery(380)} {
        font-size: 12px;
    }
`

const DevSC = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 97px;
    width: 100%;
    height: 24px;
    border: 1px solid #fdfdfd;
    border-radius: 30px;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
    color: #fdfdfd;
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 8px;
        max-width: 68px;
        height: 15px;
    }
    ${mediaQuery(380)} {
        max-width: 61px;
        font-size: 7px;
    }
`

const ElipseWrapperSC = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 26px;
    height: 23px;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 13px;
    }
`

const ElipseSC = styled('div')`
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #56a2fd;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 2px;
        height: 2px;
    }
`

// const HoverContentWrapperSC = styled('div')`
//     position: absolute;
//     top: 0px;
//     right: 0px;
// `

const HoverContentImgSC = styled('img')`
    border-radius: 10px;
    overflow: hidden;
    transition: all ease-in-out 0.1s;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border: ${({ isText }) => (isText ? 'none' : '1px solid #56a2fd')};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    ${mediaQuery(SCREEN_TABLE)} {
        display: none;
    }
`

export const styleFeatureItem = () => ({
    DevSC,
    TextSC,
    LogoSC,
    ElipseSC,
    LeftWrapperSC,
    ElipseWrapperSC,
    FeatureWrapperSC,
    HoverContentImgSC,
})
