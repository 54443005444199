import styled from 'styled-components'

import { mediaQuery } from '../../lib/service/service'
import { SCREEN_MOBILE } from '../../lib/const/breakpoint'
import { ContainerSC } from '../Container/StyleContainer'

const CustomContainerSC = styled(ContainerSC)`
    ${mediaQuery(870)} {
        padding: 0;
    }
`

const FormBlockSC = styled('div')`
    padding: 125px 0 125px 0;
    background-image: url('/BetaBlockAndFormBG.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
    );
    ${mediaQuery(870)} {
        padding: 0;
    }
`

const FormWrapperSC = styled('div')`
    display: flex;
    justify-content: space-between;
    background: #eaf0fa;
    border-radius: 40px;
    ${mediaQuery(870)} {
        border-radius: 0;
    }
`

const LeftWrapperSC = styled('div')`
    padding: 100px 50px 40px;
    max-width: 500px;
    width: 100%;
    background-image: url('/FormLeftBG.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
`

const TitleSC = styled('h3')`
    font-weight: 800;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: ${({ $color }) => $color};
    margin-bottom: ${({ $marginBottomPX }) => $marginBottomPX};
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-left: 10px;
        font-size: 32px;
        margin-bottom: 20px;
    }
    ${mediaQuery(380)} {
        font-size: 24px;
    }
`

const DescrSC = styled('p')`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin-bottom: 129px;
`

const QuoteWrapperSC = styled('div')`
    padding: 20px;
    max-width: 400px;
    width: 100%;
    background: #4c8bd6;
    border-radius: 21px;
`

const QuoteTextSC = styled('blockquote')`
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #fdfdfd;
    margin-bottom: 20px;
`

const QuoteAuthorWrapperSC = styled('div')`
    display: flex;
    align-items: center;
`

const QuoteAuthorImgSC = styled('img')`
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-right: 10px;
`

const QuoteRightBlockSC = styled('div')`
    display: flex;
    align-items: start;
    flex-direction: column;
`

const QuoteNameSC = styled('span')`
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: #fdfdfd;
    margin-bottom: 5px;
`

const QuoteAuthorPost = styled('span')`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-align: left;
    color: #fdfdfd;
`

const FreeSC = styled('span')`
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #ffffff;
    text-decoration: underline;
`

export const styleFormBlock = () => ({
    FreeSC,
    DescrSC,
    TitleSC,
    QuoteTextSC,
    FormBlockSC,
    QuoteNameSC,
    FormWrapperSC,
    LeftWrapperSC,
    QuoteWrapperSC,
    QuoteAuthorPost,
    QuoteAuthorImgSC,
    QuoteRightBlockSC,
    CustomContainerSC,
    QuoteAuthorWrapperSC,
})
