import styled from 'styled-components'

import { ReactComponent as Close } from './assets/close.svg'
import { SCREEN_MOBILE } from '../../lib/const/breakpoint'
import { mediaQuery } from '../../lib/service/service'

const OverlaySC = styled('div')`
    padding: 50px 5px;
    position: fixed;
    display: flex;
    align-items: center;
    overflow: hidden scroll;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(19, 18, 24, 0.9);
    z-index: 10001;
`

const CloseSC = styled(Close)`
    overflow: visible;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 22px;
    height: 22px;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 18px;
        height: 18px;
    }
`
const ChildrenWrapperSC = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const styleModalUI = () => ({
    CloseSC,
    OverlaySC,
    ChildrenWrapperSC,
})
