import styled from 'styled-components'
import { ReactComponent as CheckBoxActive } from './assets/CheckBox.svg'
import { ReactComponent as CheckBoxTableActive } from './assets/CheckBoxTable.svg'
import { mediaQuery } from '../../../../lib/service/service'
import { SCREEN_MOBILE } from '../../../../lib/const/breakpoint'

const CheckBoxInputSC = styled('input')`
    position: absolute;
    opacity: 0;
    visibility: hidden;
`
const CheckBoxsWrapperSC = styled('div')`
    margin-bottom: 30px;
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-bottom: 20px;
    }
`

const CheckBoxActiveSC = styled(CheckBoxActive)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 15px;
`
const CheckBoxActiveTableSC = styled(CheckBoxTableActive)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 15px;
`

const CheckBoxTextSC = styled('span')`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #2a2735;
    opacity: 0.6;
    ${mediaQuery(870)} {
        opacity: 1;
        color: #fdfdfd;
    }
`
const CheckBoxWrapperSC = styled('div')`
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
        & > div {
            border: 1px solid #56a2fd;
        }
    }
`

const CheckBoxSC = styled('div')`
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: #fdfdfd;
    border-radius: 4px;
    margin-right: 15px;
`

export const styleCheckBoxs = () => ({
    CheckBoxSC,
    CheckBoxTextSC,
    CheckBoxInputSC,
    CheckBoxActiveSC,
    CheckBoxWrapperSC,
    CheckBoxsWrapperSC,
    CheckBoxActiveTableSC,
})
