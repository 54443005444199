import TitleUI from '../../UI/TitleUI/TitleUI'
import Container from '../Container/Container'
import GifCard from './GifCard/GifCard'
import { styleIsSimpleBlock } from './StyleIsSimpleBlock'

const IsSimpleBlock = ({ sectionRef }) => {
    return (
        <SimpleBlockWrapperSC ref={sectionRef} id="howToUse">
            <TitleUI>это просто</TitleUI>

            <Container>
                <GifCard
                    Title={'Не требует специальных знаний'}
                    description={
                        'Просто загрузите таблицу и начинайте работать '
                    }
                    videoSrc={'/oneVideoSimple.webp'}
                />
                <GifCard
                    Title={'Сила машинного обучения'}
                    description={
                        'ИИ понимает человеческие запросы и позволяет выдавать нужные данные'
                    }
                    isRightVersion={true}
                    videoSrc={'/twoVideoSimple.webp'}
                />
                <GifCard
                    Title={'текст или графика'}
                    description={
                        'Позволяет получать как диаграммы, так и тексты для отчета'
                    }
                    videoSrc={'/threeVideoSimple.webp'}
                />
            </Container>
        </SimpleBlockWrapperSC>
    )
}

const { SimpleBlockWrapperSC } = styleIsSimpleBlock()

export default IsSimpleBlock
