import styled from 'styled-components'
import { ReactComponent as Logo } from './assets/Logo.svg'
import { ContainerSC } from '../Container/StyleContainer'
import { mediaQuery } from '../../lib/service/service'
import {
    SCREEN_MOBILE,
    SCREEN_SMALL_MOBILE,
    SCREEN_TABLE,
    SCREEN_XL,
} from '../../lib/const/breakpoint'

import { ReactComponent as Close } from './assets/Close.svg'

const HeaderWrapperSC = styled('header')`
    height: 90px;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(5px);
    width: 100%;
    z-index: 9999;
    ${mediaQuery(SCREEN_MOBILE)} {
        height: 50px;
    }
`
const CustomContainerSC = styled(ContainerSC)`
    display: flex;
    padding: 0 60px 0px 80px;
    justify-content: space-between;
    align-items: center;
    max-width: 1500px;

    ${mediaQuery(SCREEN_XL)} {
        padding: 0 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 0 10px;
    }
`

const LogoWrapperSC = styled('a')`
    display: flex;
    align-items: center;
`

const LogoSC = styled(Logo)`
    margin-right: 10px;
`

const HeaderTitleSC = styled('h3')`
    font-weight: 700;
    font-family: 'SF Pro Display';
    font-size: 20px;
    line-height: 24px;
    color: #fdfdfd;
    ${mediaQuery(1070)} {
        font-size: 16px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 13px;
        line-height: 16px;
    }
`

const NavMenuSC = styled('nav')`
    transition: all ease-in-out 0.4s;
    display: flex;
    justify-content: space-between;
    max-width: 700px;
    /* min-width: 600px; */
    width: 100%;
    margin: 0 10px;
    ${mediaQuery(SCREEN_TABLE)} {
        background-blend-mode: multiply;
        background-image: url('/MobileNavBG.webp');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgba(0, 0, 0, 0.9);
        padding-top: 80px;
        display: flex;
        flex-direction: column;
        position: fixed;
        justify-content: space-between;
        align-items: center;
        top: 0;
        right: ${({ $viewPort, $isViewNavMobile }) =>
            $isViewNavMobile ? '0px' : `-${$viewPort.width}px`};
        ${({ $viewPort }) => {
            return `width:${$viewPort.width}px;
                 height:${$viewPort.height}px;max-width:${$viewPort.width}px`
        }};
        margin: 0;
    }
`

const NavTagSC = styled('a')`
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease-in-out 0.1s;
    height: 65px;
    font-weight: 500;
    font-size: 14px;
    line-height: 148%;
    text-align: center;
    color: #fdfdfd;
    margin: 0 7px;
    text-decoration: none;
    &::after {
        content: '';
        position: absolute;
        width: ${({ isVisible }) => (isVisible ? '100%' : '0')};
        height: 1px;
        background: #56a2fd;
        transition: width 0.1s ease-in-out;
        bottom: 0;
        left: 0;
    }
    &:hover {
        &::after {
            width: 100%;
        }
    }
    ${mediaQuery(1070)} {
        font-size: 12px;
    }
    ${mediaQuery(SCREEN_TABLE)} {
        height: auto;
        font-size: 22px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 18px;
    }
    ${mediaQuery(SCREEN_SMALL_MOBILE)} {
        font-size: 14px;
    }
`

const HeaderButtonSC = styled('a')`
    transition: all ease-in-out 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 223px;
    width: 100%;
    height: 41px;
    background: #56a2fd;
    border-radius: 39px;
    font-weight: 500;
    font-size: 14px;
    line-height: 148%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #fdfdfd;
    &:hover {
        background: #81baff;
    }
    ${mediaQuery(1070)} {
        font-size: 12px;
        height: 35px;
    }
    ${mediaQuery(SCREEN_TABLE)} {
        padding: 30px 15px;
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        height: 93px;
        background: ${({ isWhite }) => (isWhite ? '#FDFDFD' : '#56A2FD')};
        font-size: 22px;
        line-height: 148%;
        text-align: center;
        letter-spacing: 0.02em;
        color: ${({ isWhite }) => (isWhite ? '#56A2FD' : '#fdfdfd')};
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 18px;
        height: 67px;
    }
    ${mediaQuery(SCREEN_SMALL_MOBILE)} {
        font-size: 14px;
        height: 48px;
    }
`

const MobileButtonSC = styled('button')`
    width: 22px;
    height: 22px;
    border-top: 2px solid #56a2fd;
    border-bottom: 2px solid #56a2fd;
    background-color: inherit;

    ${mediaQuery(SCREEN_MOBILE)} {
        width: 18px;
        height: 18px;
    }
`
const MobileSpanSC = styled('div')`
    height: 2px;
    background: #56a2fd;
`

const CloseIconSC = styled(Close)`
    transition: all ease-in-out 0.4s;
    visibility: 'hidden';
    ${mediaQuery(SCREEN_TABLE)} {
        visibility: ${({ $isViewNavMobile }) =>
            $isViewNavMobile ? 'visible' : 'hidden'};
        overflow: visible;
        position: fixed;
        top: 17px;
        right: ${({ $viewPort, $isViewNavMobile }) =>
            $isViewNavMobile ? '17px' : `-${$viewPort.width}px`};
        width: 22px;
        height: 22px;
        z-index: 10000;
    }

    ${mediaQuery(SCREEN_MOBILE)} {
        width: 18px;
        height: 18px;
    }
`

const ButtonsWrapperSC = styled('div')`
    display: flex;
    max-width: 230px;
    width: 100%;
    justify-content: space-between;
    ${mediaQuery(SCREEN_TABLE)} {
        max-width: 100%;
        flex-direction: column;
    }
`

const AuthButtonSC = styled('a')`
    transition: all ease-in-out 0.1s;
    display: block;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 39px;
    background: ${({ isBackGround }) => (isBackGround ? '#56a2fd' : 'none')};
    color: #fdfdfd;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%; /* 20.72px */
    letter-spacing: 0.28px;
    &:hover {
        ${({ isBackGround }) =>
            isBackGround ? 'background:#81baff' : 'color:#56A2FD'}
    }
`

export const styleHeader = () => ({
    LogoSC,
    NavTagSC,
    NavMenuSC,
    CloseIconSC,
    MobileSpanSC,
    AuthButtonSC,
    HeaderTitleSC,
    LogoWrapperSC,
    HeaderButtonSC,
    MobileButtonSC,
    HeaderWrapperSC,
    ButtonsWrapperSC,
    CustomContainerSC,
})
