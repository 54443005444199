import styled from 'styled-components'
import { ReactComponent as Plus } from './assets/Plus.svg'
import { ReactComponent as TaskOne } from './assets/TaskOneBg.svg'
import { ReactComponent as TaskTwo } from './assets/TaskTwoBg.svg'
import { mediaQuery } from '../../../lib/service/service'
import {
    SCREEN_MD,
    SCREEN_MOBILE,
    SCREEN_TABLE,
} from '../../../lib/const/breakpoint'

const TaskCardWrapperSC = styled('div')`
    /* background-attachment: fixed; */
    position: relative;
    overflow: hidden;
    padding: 80px 30px 20px 30px;
    max-width: 484px;
    width: 49%;
    border: 1px solid #92c4ff;
    border-radius: 20px;
    background-color: #3c394b;
    /* background-image: ${({ bgUrl }) =>
        `url(${bgUrl === 'left' ? '/TaskOneBg.svg' : '/TaskTwoBg.svg'})`};
    background-size: ${({ bgUrl }) =>
        bgUrl === 'left' ? '218px 214px' : '200px 200px'};
    background-position: ${({ bgUrl }) =>
        bgUrl === 'left' ? '-82px -54px' : '362px -57px'};
    background-repeat: no-repeat; */
    ${mediaQuery(SCREEN_TABLE)} {
        padding: 80px 25px 20px 25px;
    }
    ${mediaQuery(SCREEN_MD)} {
        padding: 40px 20px 5px 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    ${mediaQuery(500)} {
        padding: 30px 20px 5px 20px;
    }
    ${mediaQuery(390)} {
        padding: 25px 15px 5px 15px;
    }
`

const TaskCardIconOneSC = styled(TaskOne)`
    width: 228px;
    height: 225px;
    position: absolute;
    top: -61px;
    left: -97px;
`

const TaskCardIconTwoSC = styled(TaskTwo)`
    width: 228px;
    height: 225px;
    position: absolute;
    top: -61px;
    right: -91px;
`

const TaskCardSubTitleSC = styled('h5')`
    font-weight: 400;
    font-size: 16px;
    line-height: 148%;
    letter-spacing: 0.01em;
    color: #fdfdfd;
    opacity: 0.4;
    ${mediaQuery(SCREEN_MD)} {
        font-size: 12px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 16px;
    }
    ${mediaQuery(500)} {
        font-size: 14px;
    }
`

const TaskCardTitleSC = styled('h4')`
    position: relative;
    font-weight: 600;
    font-size: 33px;
    line-height: 148%;
    letter-spacing: 0.01em;
    color: #fdfdfd;
    margin-bottom: 52px;
    max-width: 1250px;
    width: 100%;
    ${mediaQuery(SCREEN_MD)} {
        font-size: 25px;
        margin-bottom: 25px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 33px;
    }
    ${mediaQuery(500)} {
        font-size: 20px;
        margin-bottom: 12px;
    }
`

const TaskCardListSC = styled('ul')``

const TaskCardListItemSC = styled('li')`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    list-style: none;
    height: 50px;
    /* ${mediaQuery(SCREEN_MD)} {
        height: 40px;
    } */
    /* ${mediaQuery(SCREEN_MOBILE)} {
        height: 50px;
    } */
    ${mediaQuery(500)} {
        /* height: 40px; */
        margin-bottom: 15px;
    }
    ${mediaQuery(390)} {
        margin-bottom: 10px;
    }
`

const PlusIconSC = styled(Plus)`
    margin-right: 20px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    & > path {
        fill: ${({ $plusColor }) => $plusColor};
    }

    ${mediaQuery(SCREEN_MD)} {
        width: 15px;
        height: 15px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 20px;
        height: 20px;
    }
    ${mediaQuery(500)} {
        width: 16px;
        height: 16px;
    }
    ${mediaQuery(390)} {
        width: 14px;
        height: 14px;
        margin-right: 10px;
    }
`

const TextListItemSC = styled('p')`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #fdfdfd;
    ${mediaQuery(SCREEN_MD)} {
        font-size: 14px;
    }

    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 18px;
    }
    ${mediaQuery(500)} {
        font-size: 14px;
    }
    ${mediaQuery(390)} {
        font-size: 12px;
    }
`

export const styleTaskCard = () => ({
    PlusIconSC,
    TaskCardListSC,
    TextListItemSC,
    TaskCardTitleSC,
    TaskCardWrapperSC,
    TaskCardIconOneSC,
    TaskCardIconTwoSC,
    TaskCardSubTitleSC,
    TaskCardListItemSC,
})
