import { useResize } from '../../lib/hooks/useResize'

export const useTitleBlock = () => {
    const { isScreenTable, isScreenMediumTable, isScreenMobile, isScreenXl } =
        useResize()

    return {
        isScreenXl,
        isScreenTable,
        isScreenMobile,
        isScreenMediumTable,
    }
}
