import styled, { css, keyframes } from 'styled-components'

import { ReactComponent as Pending } from './icons/pending.svg'
import { ReactComponent as Error } from './icons/error.svg'
import { ReactComponent as Success } from './icons/success.svg'
import { ReactComponent as ProgressIcon } from './icons/progressIcon.svg'
import { mediaQuery } from '../../lib/service/service'
import { SCREEN_MEDIUM_MOBILE } from '../../lib/const/breakpoint'

const showAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const BackgroundSC = styled('div')`
    animation: ${showAnimation} 1s ease-in-out;
    transition: all 0.1s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: ${({ isEndAnim }) => (isEndAnim ? 0 : 1)};
`

const WrapperSC = styled('div')`
    border: 20px solid #2a2735;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    background-color: #3c394b;
    min-width: 300px;
    max-width: 500px;
    min-height: 540px;
    width: 100%;
    padding: 40px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ProgressWrapperSC = styled('div')`
    margin-bottom: 122px;
    max-width: 305px;
    width: 100%;
    ${mediaQuery(SCREEN_MEDIUM_MOBILE)} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const ProgressTitleSC = styled('span')`
    color: ${({ $color }) => $color};
    text-align: center;
    font-size: 16px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 13px;
`

const ProgressWrapperTitleSC = styled('div')`
    max-width: 305px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${mediaQuery(SCREEN_MEDIUM_MOBILE)} {
        margin-left: 20px;
        max-width: 196px;
    }
`

const ProgressWrapperLineSC = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ProgressIconSC = styled(ProgressIcon)`
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    margin-left: 6px;
`

const ProgressCircleSC = styled('div')`
    border-radius: 15px;
    border: 3px solid ${({ $color }) => (!!$color ? $color : '#8A8B97')};
    background: #eaf0fa;
    width: 15px;
    height: 15px;
`

const ProgressLineSC = styled('div')`
    height: 4px;
    background-color: ${({ $color }) => (!!$color ? $color : '#8A8B97')};
    width: ${({ $widthCustom }) => {
        return !!$widthCustom ? $widthCustom : '57px'
    }};
`

const IconCSS = css`
    max-width: 101px;
    max-height: 101px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    margin-bottom: 19px;
`

const SuccessIconSC = styled(Pending)`
    ${IconCSS}
`

const ErrorIconSC = styled(Error)`
    ${IconCSS}
`

const PendingAnimation = keyframes`
    from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const PendingIconSC = styled(Success)`
    border-radius: 61px;
    transform-origin: center;
    animation: 2s infinite ${PendingAnimation} ease-in-out;
    ${IconCSS}
`

const ResultTitleSC = styled('h2')`
    color: ${({ color }) => color};
    text-align: center;
    font-size: 26px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 20px;
`

const ResultTextSC = styled('p')`
    color: #eaf0fa;
    text-align: center;
    font-size: 16px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 60px;
`

const ButtonSC = styled('button')`
    cursor: pointer;
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #56a2fd;
    color: #fdfdfd;
    text-align: center;
    font-size: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;
    max-width: 142px;
    width: 100%;
    height: 60px;
    transition: all ease-in-out 0.1s;
    &:hover {
        background: #81baff;
    }
`

export const ModalConfirmationStyle = () => ({
    ButtonSC,
    WrapperSC,
    ErrorIconSC,
    BackgroundSC,
    ResultTextSC,
    PendingIconSC,
    SuccessIconSC,
    ResultTitleSC,
    ProgressIconSC,
    ProgressLineSC,
    ProgressTitleSC,
    ProgressCircleSC,
    ProgressWrapperSC,
    ProgressWrapperLineSC,
    ProgressWrapperTitleSC,
})
