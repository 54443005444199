import { styleTaskCard } from './StyleTaskCard'

const TaskCard = ({ subTitle, title, ListArr, plusColor, iconVersion }) => {
    return (
        <TaskCardWrapperSC bgUrl={iconVersion}>
            {iconVersion === 'one' ? (
                <TaskCardIconOneSC />
            ) : (
                <TaskCardIconTwoSC />
            )}

            <TaskCardSubTitleSC>{subTitle}</TaskCardSubTitleSC>
            <TaskCardTitleSC>{title}</TaskCardTitleSC>
            <TaskCardListSC>
                {ListArr.map((item) => {
                    return (
                        <TaskCardListItemSC key={item}>
                            <PlusIconSC $plusColor={plusColor} />
                            <TextListItemSC>{item}</TextListItemSC>
                        </TaskCardListItemSC>
                    )
                })}
            </TaskCardListSC>
        </TaskCardWrapperSC>
    )
}

const {
    PlusIconSC,
    TaskCardListSC,
    TextListItemSC,
    TaskCardTitleSC,
    TaskCardWrapperSC,
    TaskCardIconOneSC,
    TaskCardIconTwoSC,
    TaskCardSubTitleSC,
    TaskCardListItemSC,
} = styleTaskCard()

export default TaskCard
