import styled from 'styled-components'
import { mediaQuery } from '../../../../lib/service/service'
import { SCREEN_MOBILE } from '../../../../lib/const/breakpoint'

const InputLabelWrapperSC = styled('div')`
    display: flex;
    justify-content: space-between;
`

const InputErrorSC = styled('p')`
    font-weight: 500;
    font-size: 14px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: #da4b4b;
`

const InputTitleSC = styled('h5')`
    font-weight: 500;
    font-size: 14px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: #2a2735;
    margin-bottom: 10px;
    ${mediaQuery(870)} {
        color: #fdfdfd;
    }
`

const InputSC = styled('input')`
    transition: all ease-in-out 0.1s;
    max-width: 400px;
    width: 100%;
    height: 46px;
    background: #fdfdfd;
    border-radius: 8px;
    padding: 12px 20px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: #2a2735;
    border: 1px solid #fdfdfd;
    &::placeholder {
        color: #8a8b97;
        opacity: 0.5;
    }
    &:hover {
        border: 1px solid #56a2fd;
    }
    &:focus {
        border: 1px solid #56a2fd;
    }
    &:focus-visible {
        outline-width: 0;
    }
    ${mediaQuery(870)} {
        max-width: 100%;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        height: 44px;
    }
    ${mediaQuery(380)} {
        height: 39px;
    }
`
export const styleInput = () => ({
    InputSC,
    InputErrorSC,
    InputTitleSC,
    InputLabelWrapperSC,
})
