import styled from 'styled-components'
import { mediaQuery } from '../../lib/service/service'
import {
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_XL,
} from '../../lib/const/breakpoint'

const SimpleBlockWrapperSC = styled('section')`
    padding-top: 93px;
    ${mediaQuery(SCREEN_XL)} {
        padding-top: 80px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        padding-top: 60px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding-top: 50px;
    }
`

export const styleIsSimpleBlock = () => ({
    SimpleBlockWrapperSC,
})
