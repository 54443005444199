import { useEffect } from 'react'

export const useModalUI = () => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden'
        return () => {
            document.body.style.overflowY = 'scroll'
        }
    }, [])
}
