import { styleFeatureItem } from './StyleFeatureItem'

const FeatureItem = ({
    text,
    isDev,
    img,
    onClickMobile,
    isText,
    width,
    height,
}) => {
    const test = isDev ? null : onClickMobile(img, width, height)

    return (
        <FeatureWrapperSC $isDev={isDev} onClick={test}>
            <LeftWrapperSC>
                <LogoSC $isDev={isDev} />
                <TextSC $isDev={isDev}>{text}</TextSC>
            </LeftWrapperSC>
            {isDev && <DevSC>В разработке</DevSC>}
            {!isDev && (
                <ElipseWrapperSC>
                    <ElipseSC />
                    <ElipseSC />
                    <ElipseSC />
                </ElipseWrapperSC>
            )}
            {img && (
                <HoverContentImgSC
                    width={width}
                    height={height}
                    isText={isText}
                    src={img}
                    alt={text}
                />
            )}
        </FeatureWrapperSC>
    )
}

const {
    DevSC,
    TextSC,
    LogoSC,
    ElipseSC,
    LeftWrapperSC,
    ElipseWrapperSC,
    FeatureWrapperSC,
    HoverContentImgSC,
} = styleFeatureItem()

export default FeatureItem
