import styled from 'styled-components'
import { mediaQuery } from '../../lib/service/service'
import {
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_TABLE,
} from '../../lib/const/breakpoint'

const FreedomBlockWrapperSC = styled('section')`
    padding-top: 20px;
    padding-bottom: 100px;
    ${mediaQuery(SCREEN_TABLE)} {
        padding-bottom: 80px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        padding-bottom: 60px;
    }
    ${mediaQuery(380)} {
        padding-bottom: 50px;
    }
`
const FeaturesBlockSC = styled('div')`
    padding: 80px 30px 10px 30px;
    background-image: url('./backGroundSimlAndFreedomBlock.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #6a7dc0;
    border-radius: 30px;
    margin-bottom: 40px;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        padding: 40px 20px 20px 20px;
        margin-bottom: 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 25px 10px 10px 10px;
        margin-bottom: 15px;
        border-radius: 10px;
    }
    ${mediaQuery(380)} {
        margin-bottom: 10px;
    }
`

const FeatureItemTitleSC = styled('h3')`
    font-weight: 700;
    font-size: 35px;
    line-height: 25px;
    color: #fdfdfd;
    margin-bottom: 30px;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        font-size: 25px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 18px;
        margin-bottom: 20px;
    }
`

const FeatureListSC = styled('ul')``

const DevFeatureWrapperSC = styled(FeaturesBlockSC)`
    padding-bottom: 30px;
    padding-top: 30px;
    padding-right: 50px;
    /* opacity: 0.5; */
    margin: 0;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        padding-right: 40px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 10px;
        padding-right: 20px;
    }
`

// const DevFeatureBlockSC = styled('div')`
//     width: 100%;
//     height: 85px;
//     background: url(image.png), #2a2735;
//     opacity: 0.5;
//     border: 1px solid #6a7dc0;
//     border-radius: 20px;
// `

const DevTitleWrapperSC = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const DevTitleSC = styled('h3')`
    font-weight: 700;
    font-size: 35px;
    line-height: 140%;
    color: #fdfdfd;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        font-size: 25px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 18px;
    }
    ${mediaQuery(380)} {
        font-size: 14px;
    }
`

const DevMessageSC = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97px;
    height: 24px;
    border: 1px solid #fdfdfd;
    border-radius: 30px;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
    color: #fdfdfd;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 68px;
        font-size: 8px;
    }
`

const ImgModalSC = styled('img')`
    border-radius: 10px;
    ${mediaQuery(SCREEN_TABLE)} {
        padding: 0 0px;
        max-width: 100%;
        width: 100%;
        /* width: ${({ isModalSize }) => `${isModalSize.width}px`}; */
        /* max-height: 300px; */
    }
`

const ElipseWrapperSC = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 26px;
    height: 23px;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 13px;
    }
`

const ElipseSC = styled('div')`
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #56a2fd;
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 2px;
        height: 2px;
    }
`

export const styleFreedomBlock = () => ({
    ElipseSC,
    DevTitleSC,
    ImgModalSC,
    DevMessageSC,
    FeatureListSC,
    ElipseWrapperSC,
    FeaturesBlockSC,
    DevTitleWrapperSC,
    FeatureItemTitleSC,
    DevFeatureWrapperSC,
    FreedomBlockWrapperSC,
})
