import styled from 'styled-components'
import { mediaQuery } from '../../../lib/service/service'
import {
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_TABLE,
} from '../../../lib/const/breakpoint'

const WrapperCardSC = styled('div')`
    width: 100%;
    background-image: url('./backGroundSimlAndFreedomBlock.svg');
    padding: 30px;
    background-color: #2a2735;
    border: 1px solid #6a7dc0;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    height: 410px;
    ${({ $isRightVersion }) => $isRightVersion && 'flex-direction:row-reverse'};
    ${mediaQuery(SCREEN_TABLE)} {
        height: auto;
        margin-bottom: 40px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        margin-bottom: 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 30px 15px 15px 15px;
        margin-bottom: 15px;
        flex-direction: column;
    }
`

const TextWrapperSC = styled('div')`
    max-width: 360px;
    width: 100%;
    ${({ $isRightVersion }) =>
        $isRightVersion ? 'margin-left:30px' : 'margin-right:30px'};
    ${mediaQuery(SCREEN_TABLE)} {
        max-width: 288px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        max-width: 100%;
        ${({ $isRightVersion }) =>
            $isRightVersion ? 'margin-left:0px' : 'margin-right:0px'};
    }
`

const TitleSC = styled('h3')`
    max-width: 343px;
    font-weight: 700;
    font-size: 25px;
    line-height: 148%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #56a2fd;
    ${mediaQuery(SCREEN_TABLE)} {
        max-width: 310px;
        font-size: 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-bottom: 5px;
        max-width: 100%;
        font-size: 18px;
    }
    ${mediaQuery(385)} {
        font-size: 14px;
    }
`

const DescriptionSC = styled('p')`
    max-width: 360px;
    font-weight: 500;
    font-size: 14px;
    line-height: 148%;
    color: #eaf0fa;
    ${mediaQuery(SCREEN_MOBILE)} {
        max-width: 100%;
        margin-bottom: 10px;
    }
    ${mediaQuery(385)} {
        font-size: 12px;
    }
`

const VideoSC = styled('img')`
    object-fit: cover;
    max-width: 550px;
    width: 60%;
    /* height: 350px; */
    background: #fff;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);

    ${mediaQuery(SCREEN_MOBILE)} {
        width: 100%;
    }
`

export const styleGifCard = () => ({
    TitleSC,
    VideoSC,
    TextWrapperSC,
    DescriptionSC,
    WrapperCardSC,
})
