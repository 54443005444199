import styled from 'styled-components'
import { ContainerSC } from '../Container/StyleContainer'
import {
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_TABLE,
} from '../../lib/const/breakpoint'
import { mediaQuery } from '../../lib/service/service'

const FooterSC = styled('footer')`
    width: 100%;
    height: 250px;
    padding: 40px 80px;
    background-image: url('/FooterBG.webp');
    background-repeat: no-repeat;
    background-size: cover;
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 30px 10px 20px 10px;
        height: auto;
    }
`

const CustomContainerSC = styled(ContainerSC)`
    display: flex;
    /* padding: 0 80px 0 100px; */
    padding: 0;
    justify-content: space-between;
    max-width: 1540px;
    ${mediaQuery(SCREEN_TABLE)} {
        /* padding: 0 0px 0px 20px; */
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        flex-wrap: wrap;
        /* padding: 0 0 0 10px; */
    }
`

const CompanyWrapperSC = styled(CustomContainerSC)`
    justify-content: space-between;
    align-items: center;
    padding: 0;
    /* ${mediaQuery(SCREEN_MOBILE)} {
        justify-content: start;
    } */
`
const PartWrapperSC = styled('div')`
    margin-right: 20px;
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-right: 10px;
    }
`

const PartTitleSC = styled('h4')`
    font-weight: 700;
    font-size: 18px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: #fdfdfd;
    margin-bottom: 20px;
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 14px;
        margin-bottom: 15px;
    }
`

const AddressSC = styled('a')`
    transition: all 0.4s;
    display: block;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #b3b3b3;
    margin-bottom: 40px;
    max-width: 210px;
    white-space: pre-line;
    &:hover {
        color: #56a2fd;
    }
    ${mediaQuery(686)} {
        font-size: 14px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        margin-bottom: 30px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 10px;
        margin-bottom: 20px;
    }
    ${mediaQuery(380)} {
        font-size: 12px;
    }
`

const CompanySC = styled('div')`
    display: flex;
    ${mediaQuery(840)} {
        flex-direction: column;
        height: 50px;
        justify-content: space-between;
    }
`
const CompanyItemSC = styled('span')`
    font-weight: 300;
    font-size: 14px;
    line-height: 120%;
    color: #fdfdfd;
    margin-right: 10px;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        font-size: 12px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 10px;
    }
    ${mediaQuery(335)} {
        max-width: 106px;
    }
`

const ContactItemSC = styled('a')`
    transition: all 0.4s;
    cursor: pointer;
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #b3b3b3;
    margin-bottom: 12px;
    &:hover {
        color: #56a2fd;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 10px;
        margin-bottom: 10px;
    }
    ${mediaQuery(380)} {
        font-size: 12px;
    }
`

const SocialWrapperSC = styled('div')`
    display: flex;

    ${mediaQuery(SCREEN_MOBILE)} {
        width: 365px;
        margin-bottom: 30px;
    }
`

const SocialItemSC = styled('a')`
    transition: all 0.4s;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    border: 1px solid #b3b3b3;
    border-radius: 4px;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #b3b3b3;
    margin-right: 20px;
    &:hover {
        color: #56a2fd;
        border: 1px solid #56a2fd;
    }

    ${mediaQuery(SCREEN_MOBILE)} {
        font-size: 12px;
        line-height: 15px;
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
`

export const styleFooter = () => ({
    FooterSC,
    AddressSC,
    CompanySC,
    PartTitleSC,
    SocialItemSC,
    CompanyItemSC,
    ContactItemSC,
    PartWrapperSC,
    SocialWrapperSC,
    CompanyWrapperSC,
    CustomContainerSC,
})
