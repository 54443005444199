import { useEffect, useState } from 'react'
import { useResize } from '../../lib/hooks/useResize'

export const useHeader = () => {
    const [viewPort, setViewPort] = useState(window.visualViewport)
    const [isViewNavMobile, setViewNavMobile] = useState(false)
    const { isScreenTable } = useResize()
    const getViewPort = () => {
        const viewportHeight = window.visualViewport
        setViewPort(viewportHeight)
    }

    const handleNavButton = (e) => {
        e.stopPropagation()
        if (!isScreenTable) {
            setViewNavMobile((prev) => {
                document.body.style.overflow = `${prev ? 'scroll' : 'hidden'}`
                return !prev
            })
        }
    }
    useEffect(() => {
        window.addEventListener('resize', getViewPort)
    }, [])

    return {
        viewPort,
        isScreenTable,
        handleNavButton,
        isViewNavMobile,
    }
}
