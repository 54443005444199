import Footer from '../Component/Footer/Footer'
import Header from '../Component/Header/Header'
const MainLayout = ({ children, sectionRefs }) => {
    return (
        <>
            <Header sectionRefs={sectionRefs} />
            <main>{children}</main>
            <Footer />
        </>
    )
}

export default MainLayout
