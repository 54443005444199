import { useState, useEffect } from 'react'
import {
    SCREEN_MD,
    SCREEN_XL,
    SCREEN_XXL,
    SCREEN_TABLE,
    SCREEN_MOBILE,
    SCREEN_SMALL_MOBILE,
    SCREEN_MEDIUM_TABLE,
    SCREEN_MEDIUM_MOBILE,
} from '../const/breakpoint'

export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return {
        width,
        isScreenSm: width > SCREEN_MOBILE,
        isScreenMd: width > SCREEN_MD,
        isScreenTable: width > SCREEN_TABLE,
        isScreenLg: width > SCREEN_TABLE,
        isScreenXl: width > SCREEN_XL,
        isScreenXxl: width > SCREEN_XXL,
        isScreenMobile: width > SCREEN_MOBILE,
        isScreenSmallMobile: width > SCREEN_SMALL_MOBILE,
        isScreenMediumTable: width > SCREEN_MEDIUM_TABLE,
        isScreenMediumMobile: width > SCREEN_MEDIUM_MOBILE,
    }
}
