import styled, { css } from 'styled-components'
import { ContainerSC } from '../Container/StyleContainer'
import { mediaQuery } from '../../lib/service/service'
import {
    SCREEN_MEDIUM_MOBILE,
    SCREEN_MEDIUM_TABLE,
    SCREEN_MOBILE,
    SCREEN_TABLE,
    SCREEN_XL,
} from '../../lib/const/breakpoint'

const TitleSCC = css`
    color: #fdfdfd;
    max-width: 756px;
    width: 100%;
    margin-bottom: 40px;
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-bottom: 30px;
    }
`

const CustomContainerSC = styled(ContainerSC)`
    display: flex;
    align-items: end;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0;
    height: 100%;
    min-width: 756px;
    ${mediaQuery(SCREEN_XL)} {
        min-width: 574px;
    }
    ${mediaQuery(SCREEN_TABLE)} {
        min-width: 400px;
    }
    ${mediaQuery(SCREEN_MEDIUM_MOBILE - 1)} {
        min-width: 300px;
    }
`
const TitleBlockWrapperSC = styled('div')`
    height: 100%;
    margin: 0 auto;
    padding: 0 0 0 80px;
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
    align-items: end;
    ${mediaQuery(SCREEN_TABLE)} {
        padding-left: 40px;
        padding-bottom: 49px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        flex-direction: column;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        height: auto;

        padding: 120px 0px 0px 20px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        align-items: start;
        padding: 91px 0 0 0px;
        padding-bottom: 0;
        border: none;
    }
`

const TitleBlockSC = styled('section')`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        url('TitleBlockBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: 16px solid #000000;
    height: 796px;
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        height: auto;
        border-bottom: 0px;
    }
`

const LeftSideWrapperSC = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-width: 756px;
    ${mediaQuery(SCREEN_XL)} {
        min-width: 574px;
    }
    ${mediaQuery(SCREEN_TABLE)} {
        min-width: 400px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        max-width: 430px;
        overflow: visible;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        max-width: 300px;
        min-width: 300px;
        margin: 0 auto;
    }
`

const TitleSC = styled('h1')`
    font-weight: 600;
    font-size: 60px;
    line-height: 130%;
    ${TitleSCC};
    ${mediaQuery(SCREEN_TABLE)} {
        font-size: 40px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        max-width: 430px;
        font-weight: 600;
        font-size: 30px;
        line-height: 130%;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        text-align: center;
    }
`

const SubTitleSC = styled('h2')`
    font-weight: 500;
    font-size: 22px;
    line-height: 148%;
    ${TitleSCC}
    ${mediaQuery(SCREEN_TABLE)} {
        font-size: 18px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        max-width: 377px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        text-align: center;
    }
`

const ButtonSC = styled('a')`
    transition: all ease-in-out 0.1s;
    display: inline-flex;
    width: fit-content;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* width: 212px; */
    height: 60px;
    color: #fdfdfd;
    background: #56a2fd;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    &:hover {
        background: #81baff;
    }
    ${mediaQuery(SCREEN_TABLE)} {
        /* width: 293px; */
        height: 57px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        height: 51px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 100%;
        padding: 0;
        border-radius: 0;
        margin-bottom: 0;
        font-size: 16px;
    }
`

const ButtonYoutubeSC = styled('a')`
    width: 195px;
    height: 60px;
    transition: all ease-out 0.1s;
    border-radius: 8px;
    border: 1.25px solid #56a2fd;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fdfdfd;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 148%; /* 29.6px */
    letter-spacing: 0.4px;
    &:hover {
        background: #1f3958;
        border: 1.25px solid #56a2fd;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        margin-bottom: 20px;
        height: 57px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        height: 51px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 100%;
        padding: 0;
        border-radius: 0;
        margin-bottom: 0;
        font-size: 16px;
    }
`

const ImgSC = styled('img')`
    /* max-width:640px; */
    /* width: 640px; */
    transform: translateY(3px);
    height: 657px;
    /* position: absolute;
    right: 0;
    bottom: 0; */
    ${mediaQuery(SCREEN_XL)} {
        width: 495px;
    }
    ${mediaQuery(SCREEN_TABLE)} {
        height: 610px;
        width: 540px;
    }
    ${mediaQuery(SCREEN_MEDIUM_TABLE)} {
        width: 540px;
        height: 386px;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        width: 550px;
        height: 423px;
        margin-bottom: 25px;
    }
    ${mediaQuery(SCREEN_MEDIUM_MOBILE - 1)} {
        width: 381px;
        height: 275px;
        margin-left: 12px;
    }
`

export const styleTitleBlock = () => ({
    ImgSC,
    TitleSC,
    ButtonSC,
    SubTitleSC,
    TitleBlockSC,
    ButtonYoutubeSC,
    LeftSideWrapperSC,
    CustomContainerSC,
    TitleBlockWrapperSC,
})
