import TitleUI from '../../UI/TitleUI/TitleUI'
import Container from '../Container/Container'
import RatesCard from './RatesCard/RatesCard'
import { styleRatesBlock } from './StyleRatesBlock'

const RatesBlock = ({ sectionRef }) => {
    const personalItem = [
        'Работа с любыми типами БД',
        'Генерация отчетов',
        'Не требуется специальных навыков',
    ]

    const businessItem = [
        'Интеграция с вашей базой данных',
        'Обучение сотрудников',
        'Развертывание на серверах компании',
    ]

    return (
        <RatesBlockSC ref={sectionRef} id="price">
            <TitleUI>Тарифы</TitleUI>
            <Container>
                <RatesWrapeprSC>
                    <RatesCard
                        color={'#6A7DC0'}
                        listItem={personalItem}
                        price={'от 1’000₽'}
                        type={'Персональный'}
                    />
                    <RatesCard
                        color={'#56A2FD'}
                        listItem={businessItem}
                        price={'от 100’000₽'}
                        type={'бизнес'}
                    />
                </RatesWrapeprSC>
            </Container>
        </RatesBlockSC>
    )
}

const { RatesBlockSC, RatesWrapeprSC } = styleRatesBlock()

export default RatesBlock
