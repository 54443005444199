import styled from 'styled-components'
import { mediaQuery } from '../../../lib/service/service'
import { SCREEN_MOBILE } from '../../../lib/const/breakpoint'

const FormSC = styled('form')`
    padding: 90px 50px 40px 50px;
    max-width: 500px;
    width: 100%;
    ${mediaQuery(870)} {
        background-image: url('/BGFormMobile.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        max-width: 100%;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 0;
        padding-top: 40px;
        overflow: hidden;
    }
`
const TitleSC = styled('h3')`
    font-weight: 800;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: ${({ $color }) => $color};
    margin-bottom: ${({ $marginBottomPX }) => $marginBottomPX};
    ${mediaQuery(SCREEN_MOBILE)} {
        margin-left: 10px;
        font-size: 32px;
        margin-bottom: 20px;
    }
    ${mediaQuery(380)} {
        font-size: 24px;
    }
`

const InputTitleSC = styled('h5')`
    font-weight: 500;
    font-size: 14px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: #2a2735;
    margin-bottom: 10px;
    ${mediaQuery(870)} {
        color: #fdfdfd;
    }
`

const SubmitButtonSC = styled('button')`
    ${({ isActive }) => !isActive && 'opacity:0.1'};
    transition: all ease-in-out 0.2s;
    max-width: 400px;
    ${({ isActive }) => isActive && 'cursor: pointer;'};
    width: 100%;
    height: 46px;
    background: #56a2fd;
    border-radius: 8px;
    font-weight: 700;
    font-size: 17.5px;
    line-height: 148%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    &:hover {
        background: #81baff;
    }
    ${mediaQuery(870)} {
        background: #fdfdfd;
        color: #56a2fd;
        max-width: 100%;
    }
    ${mediaQuery(SCREEN_MOBILE)} {
        border-radius: 0;
        height: 53px;
        color: #56a2fd;
    }
`

const MobileWrapperSC = styled('div')`
    ${mediaQuery(SCREEN_MOBILE)} {
        padding: 0px 10px 0 10px;
    }
`
const AnimationWrapperSC = styled('div')`
    transition: all ease-in-out 0.2s;
    min-height: 304px;
    opacity: ${({ isAnimation }) =>
        isAnimation === 'firstAnim'
            ? '0'
            : isAnimation === 'secondAnim'
            ? '0.5'
            : '1'};
`

const PageCountSC = styled('span')`
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: right;
    letter-spacing: 0.02em;
    color: #798096;
    ${mediaQuery(870)} {
        color: #fdfdfd;
    }
`

const TextAreaSC = styled('textarea')`
    resize: none;
    padding: 12.5px 20px;
    width: 100%;
    height: 138px;
    background: #fdfdfd;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 14px;
    border: 1px solid #fdfdfd;
    &::placeholder {
        color: #8a8b97;
        opacity: 0.5;
        font-size: 14px;
    }
    &:hover {
        border: 1px solid #56a2fd;
    }
    &:focus {
        border: 1px solid #56a2fd;
    }
    &:focus-visible {
        outline-width: 0;
    }
    &::-webkit-scrollbar {
        box-sizing: border-box;
        width: 10px;
        padding: 4px;
    }
    &::-webkit-scrollbar-track {
        margin: 5px 0;
        width: 20px;
        padding: 3px;
        border-radius: 20px; /* закругления плашки */
        background: #fdfdfd; /* цвет дорожки */
        box-shadow: inset 0 0 10px 10px #fdfdfd;
        border: solid 3px #fdfdfd;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px #d9d9d9;
        border: solid 3px #fdfdfd;
        width: 10px;
        background-color: #d9d9d9 !important; /* цвет плашки */
        border-radius: 10px; /* закругления плашки */
    }
`

export const styleForm = () => ({
    FormSC,
    TitleSC,
    TextAreaSC,
    PageCountSC,
    InputTitleSC,
    SubmitButtonSC,
    MobileWrapperSC,
    AnimationWrapperSC,
})
